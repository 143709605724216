import React from "react";
import styled from "styled-components";
import CustomLink from "./CustomLink";
import HeroImageGrid from "./HeroImageGrid";

const HeroSectionStyle = styled.div`
  display: grid;
  --size: 100%;
  --gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(var(--size), 1fr));
  gap: var(--gap);
  margin: 60px 0;
  .hero-text-wrapper {
    display: flex;
    margin-top: 0px;
    .hero-text-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 50px;
      .text-stack {
        p {
          line-height: 25px;
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    --size: calc(50% - var(--gap)) !important;
    .hero-text-wrapper {
      margin-top: 5%;
    }
    .hero-text-inner {
      text-align: left !important;
      align-items: start !important;
    }
  }
`;

export default function HeroSection({
  images,
  title: {
    data: { heroTitle },
  },
  description: {
    data: { heroDescription },
  },
  button: { link, buttonText },
}) {
  return (
    <HeroSectionStyle>
      <div className="hero-text-wrapper">
        <div className="hero-text-inner">
          <div className="text-stack">
            <h1>{heroTitle}</h1>
            <p>{heroDescription}</p>
          </div>
          <CustomLink link={link} text={buttonText} />
        </div>
      </div>
      <HeroImageGrid images={images} />
    </HeroSectionStyle>
  );
}
