import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { px, pxToRem } from "../utils";

const ProductCardStyle = styled.article`
  width: 100%;
  margin: 0 auto;
  .gatsby-image-wrapper {
    width: 100%;
  }
  &:nth-child(odd) {
    direction: rtl;
    p {
      text-align: left;
    }
    .links {
      direction: ltr;
    }
  }
  h2 {
    margin: 0 0 20px 0;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 1.5px;
    font-size: ${({ theme: { typography } }) => pxToRem(typography.h2.sm)};
  }
  .blurred-card {
    box-shadow: -2px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: ${px(7)};
    margin: -100px 20px ${px(20)} 20px;
    background: #ffffff6b;
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: ${({ theme: { typography } }) => pxToRem(typography.body2)};
      line-height: 25px;
    }
    .links {
      margin-top: ${px(15)};
      display: flex;
      flex-direction: column;
      gap: 20px;
      a {
        display: block;
        text-transform: uppercase;
        color: white;
        padding: ${px(5.75)} ${px(4.75)};
        font-size: 0.9rem;
      }
      a:first-child {
        background-color: ${({ theme: { palette } }) => palette.secondary.main};
        border-radius: 10px;
        transition: box-shadow 250ms ease-in-out;
        &:hover {
          transition: box-shadow 250ms ease-in-out;
          box-shadow: 1px 1px 15px
            ${({ theme: { palette } }) => palette.primary[500]};
        }
      }
    }
  }
  img {
    border-radius: 15px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    .links {
      flex-direction: row !important;
      justify-content: center;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: 0 0 ${px(20)} 0;
    width: 100%;
    display: flex;
    .blurred-card {
      width: 100%;
      padding: 50px;
      margin: 20px 0px 20px -40px;
      gap: 30px;
    }
    &:nth-child(odd) {
      direction: rtl;
      .blurred-card {
        margin: 20px -40px 20px 0px;
      }
    }
  }
`;

export default function ProductCard({ description, image, name, category }) {
  const imageGetter = getImage(image.localFile);

  return (
    <ProductCardStyle>
      <GatsbyImage
        layout="constrained"
        image={imageGetter}
        alt={image.alternativeText}
      />
      <div className="blurred-card">
        <div className="text-wrapper">
          <h2>{name}</h2>
          <p>{description}</p>
        </div>
        <div className="links">
          <Link to={category.link}>{category.buttonText} →</Link>
        </div>
      </div>
    </ProductCardStyle>
  );
}
