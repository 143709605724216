import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

const HeroImageGridStyle = styled.div`
  display: flex;
  align-items: center;
  .images-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 15px;
    img {
      border-radius: 20px;
    }
    .images {
      position: relative;
      .text-overlay {
        position: absolute;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.4);
        border: 1px solid #27272a;
        padding: 10px;
        margin: 10px;
        border-radius: 20px;
        color: white;
        display: flex;
        font-weight: bolder;
        text-transform: capitalize;
        line-height: 25px;
        justify-content: center;
        align-items: center;
        text-align: center;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
      }
      & > [data-gatsby-image-wrapper] {
        height: 100%;
      }
    }
    .image-0,
    .image-1,
    .image-2 {
      grid-column: 1;
    }

    .image-2 {
      grid-row: 4;
    }

    .image-8,
    .image-9 {
      grid-column: 4;
    }

    .image-7 {
      grid-row: 4;
    }

    .image-1,
    .image-6 {
      grid-row: 2/4;
    }

    .image-3,
    .image-4 {
      grid-column: 2;
    }

    .image-3,
    .image-8 {
      grid-row: 1/3;
    }

    .image-4,
    .image-9 {
      grid-row: 3/5;
    }

    .image-5,
    .image-6,
    .image-7 {
      grid-column: 3;
    }

    .image-5,
    .image-0 {
      grid-row: 1;
    }
  }
`;

export default function HeroImageGrid({ images }) {
  return (
    <HeroImageGridStyle>
      <div className="images-wrapper">
        {images.map((image, i) => {
          const imageGetter = getImage(image.img.localFile);
          return (
            <div key={i} className={`image-${i} images`}>
              {image.text && <div className="text-overlay">{image.text}</div>}
              <GatsbyImage
                image={imageGetter}
                alt={image.img.alternativeText}
              />
            </div>
          );
        })}
      </div>
    </HeroImageGridStyle>
  );
}
