import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { px } from "../utils";
import Title from "./Title";

const AboutStyle = styled.section`
  .about-wrapper {
    margin: 40px 0 80px 0;
    position: relative;
    .about-card {
      position: absolute;
      top: 0%;
      box-shadow: -2px 4px 24px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      padding: ${px(10)};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 20px;
      height: 100%;
      z-index: 1;
      backdrop-filter: blur(10px);
      h2 {
        margin: 0;
      }
    }
    .about-card,
    [data-gatsby-image-wrapper] {
      width: 100%;
      height: 500px;
      border-radius: 10px;
    }
    a {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      background-color: ${({ theme: { palette } }) => palette.primary.main};
      color: ${({ theme: { palette } }) => palette.primary[50]};
      padding: 20px 50px;
    }
  }

  @media screen and (min-width: 900px) {
    .about-wrapper {
      margin: 40px 0 80px 0;
      display: flex;
      .about-card {
        height: unset;
        position: unset;
        box-shadow: -2px 4px 24px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        padding: ${px(10)};
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        margin: 40px -90px 40px 0;
        z-index: 10;
        backdrop-filter: blur(10px);
      }
      .about-card,
      [data-gatsby-image-wrapper] {
        width: 100%;
      }
      a {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        background-color: ${({ theme: { palette } }) => palette.primary.main};
        color: ${({ theme: { palette } }) => palette.primary[50]};
        padding: ${px(5)} ${px(12.5)};
      }
    }
  }
`;

export default function About({ image, title, summary, cardTitle }) {
  const imageGetter = getImage(image.localFile);
  return (
    <AboutStyle>
      <Title title={title} />
      <div className="about-wrapper">
        <div className="about-card">
          <h2>{cardTitle}</h2>
          <p>{summary}</p>
        </div>
        <GatsbyImage image={imageGetter} alt={image.alternativeText} />
      </div>
    </AboutStyle>
  );
}
