import * as React from "react";
import { graphql } from "gatsby";
import OurProduct from "../components/OurProduct";
import HeroSection from "../components/HeroSection";
import About from "../components/About";

export default function HomePage({ data }) {
  const {
    heroImages,
    heroTitle,
    heroDescription,
    button,
    product,
    abouUsTitle,
    aboutUsCardTitle,
    aboutUsSummary,
    aboutUsImage,
  } = data.home.nodes[0];
  return (
    <>
      <HeroSection
        images={heroImages}
        title={heroTitle}
        description={heroDescription}
        button={button}
      />
      <About
        title={abouUsTitle}
        cardTitle={aboutUsCardTitle}
        summary={aboutUsSummary}
        image={aboutUsImage}
      />
      <OurProduct title="Our Products" products={product} />
    </>
  );
}

export const homePageQuery = graphql`
  query homePageQuery {
    home: allStrapiHome {
      nodes {
        product {
          id
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 640, formats: AUTO)
              }
            }
            alternativeText
          }
          description
          category {
            buttonText
            link
          }
        }
        heroTitle {
          data {
            heroTitle
          }
        }
        heroImages {
          text
          img {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: AUTO)
              }
            }
            alternativeText
          }
        }
        heroDescription {
          data {
            heroDescription
          }
        }
        button {
          buttonText
          link
        }
        abouUsTitle
        aboutUsCardTitle
        aboutUsSummary
        aboutUsImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;
