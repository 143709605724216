import React from "react";
import styled from "styled-components";
import ProductCard from "./ProductCard";
import Title from "./Title";

const OurProductStyle = styled.section`
  margin: 40px 0 80px 0;
  h2 {
    margin-bottom: 35px;
  }
`;

export default function OurProduct({ title, products }) {
  return (
    <OurProductStyle>
      <Title title={title} scrollerId="our-products" />
      {products.map(product => (
        <ProductCard key={product.id} {...product} />
      ))}
    </OurProductStyle>
  );
}
